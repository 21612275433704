import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import nodeQueryVariables from '@aurora/shared-client/components/nodes/NodeView.query.graphql';
import useImperativeQueryWithTracing from '@aurora/shared-client/components/useImperativeQueryWithTracing';
import type {
  BoardPageParams,
  CategoryPageParams,
  EndUserRouteAndParams,
  GroupHubPageParams,
  MessageParamPages,
  MessageReplyPageParams,
  UserPageParams
} from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import { EndUserPages } from '@aurora/shared-types/pages/enums';
import type { Tenant } from '@aurora/shared-types/tenant';
import { useContext } from 'react';
import type {
  ContextMessageFragment,
  ContextMessageQuery,
  ContextMessageQueryVariables,
  NodeViewFragment,
  NodeViewQuery,
  NodeViewQueryVariables,
  UserViewFragment,
  UserViewQuery,
  UserViewQueryVariables
} from '../../types/graphql-types';
import {
  nodeHoverCardDefaultProps,
  userHoverCardDefaultProps
} from '../extendedOverlayTrigger/HoverCardHelper';
import userViewQuery from '../users/UserView.query.graphql';
import contextMessageQuery from './ContextMessage.query.graphql';
import { UrlObject } from './useContextObjectFromUrl';

interface ContextObjectResult {
  /**
   * Get the context object for the specified path.
   *
   * @param path the path.
   */
  getContextObject: (
    path: string
  ) => Promise<ContextMessageFragment | UserViewFragment | NodeViewFragment>;
}

interface ContextObjectLookup {
  /**
   * Get the UrlObject and entity id
   *
   * @param path the path
   */
  getObjectDataForPath: (path: string) => UrlObjectData;

  /**
   * Get the UrlObject and entity id
   *
   * @param route the route
   * @param params the route params
   */
  getObjectDataForRouteAndParams: (
    routeAndParams: EndUserRouteAndParams<EndUserPages>
  ) => UrlObjectData;
}

interface UrlObjectData {
  /**
   * The UrlObject.
   */
  urlObject: UrlObject;
  /**
   * The entity ID for the URL Object.
   */
  entityId: string;
}

/**
 * Get the object id and type from a route and params.
 *
 * @param tenant the tenant
 * @param route the route
 * @param params the route params
 */
export function getObjectForRouteAndParams(
  tenant: Tenant,
  { route, params }: EndUserRouteAndParams<EndUserPages> = { route: null, params: null }
): UrlObjectData {
  let urlObject;
  let entityId;
  switch (route) {
    case EndUserPages.UserPage: {
      urlObject = UrlObject.USER;
      entityId = `user:${(params as UserPageParams).userId}`;
      break;
    }

    case EndUserPages.TkbMessagePage:
    case EndUserPages.BlogMessagePage:
    case EndUserPages.ForumMessagePage:
    case EndUserPages.IdeaMessagePage:
    case EndUserPages.OccasionMessagePage: {
      urlObject = UrlObject.MESSAGE;
      entityId = `message:${(params as MessageParamPages).messageId}`;
      break;
    }

    case EndUserPages.BlogReplyPage:
    case EndUserPages.ForumReplyPage:
    case EndUserPages.TkbReplyPage:
    case EndUserPages.IdeaReplyPage:
    case EndUserPages.OccasionReplyPage: {
      urlObject = UrlObject.REPLY;
      entityId = `message:${(params as MessageReplyPageParams).replyId}`;
      break;
    }

    case EndUserPages.ForumBoardPage:
    case EndUserPages.BlogBoardPage:
    case EndUserPages.TkbBoardPage:
    case EndUserPages.IdeaBoardPage:
    case EndUserPages.EventBoardPage: {
      urlObject = UrlObject.BOARD;
      const { boardId } = params as BoardPageParams;
      entityId = `board:${boardId}`;
      break;
    }

    case EndUserPages.CommunityPage: {
      // A url object type from Community could be added as needed, omitting for now.
      urlObject = null;
      entityId = `${NodeType.COMMUNITY}:${tenant?.community}`;
      break;
    }

    case EndUserPages.CategoryPage: {
      urlObject = UrlObject.CATEGORY;
      const { categoryId } = params as CategoryPageParams;
      entityId = `category:${categoryId}`;
      break;
    }

    case EndUserPages.GroupHubPage: {
      urlObject = UrlObject.GROUPHUB;
      const { groupHubId } = params as GroupHubPageParams;
      entityId = `grouphub:${groupHubId}`;
      break;
    }

    default: {
      break;
    }
  }

  return { urlObject, entityId };
}

/**
 * Provides a helper for accessing information about the primary object for a given
 * URL path or a route and params.
 */
export function useContextObjectDataHelper(): ContextObjectLookup {
  const { router } = useEndUserRoutes();
  const tenant = useContext(TenantContext);

  function getObjectDataForPath(path: string): UrlObjectData {
    const routeAndParams = router.getRouteAndParamsByPath(path);
    return getObjectForRouteAndParams(tenant, routeAndParams);
  }

  function getObjectDataForRouteAndParamsWithTenant(
    routeAndParams: EndUserRouteAndParams<EndUserPages>
  ): UrlObjectData {
    return getObjectForRouteAndParams(tenant, routeAndParams);
  }

  return {
    getObjectDataForPath,
    getObjectDataForRouteAndParams: getObjectDataForRouteAndParamsWithTenant
  };
}

/**
 * Get the context object from the given path.
 *
 * @param module the module.
 *
 * @author Vaibhav Chawla
 */
export default function useContextObjectFromPath(module: NodeModule | string): ContextObjectResult {
  const { getObjectDataForPath } = useContextObjectDataHelper();

  const refetchMessage = useImperativeQueryWithTracing<
    ContextMessageQuery,
    ContextMessageQueryVariables
  >(module, contextMessageQuery);
  const refetchNode = useImperativeQueryWithTracing<NodeViewQuery, NodeViewQueryVariables>(
    module,
    nodeQueryVariables
  );
  const refetchUser = useImperativeQueryWithTracing<UserViewQuery, UserViewQueryVariables>(
    module,
    userViewQuery
  );

  return {
    getContextObject: async (
      path: string
    ): Promise<ContextMessageFragment | UserViewFragment | NodeViewFragment> => {
      const { urlObject, entityId } = getObjectDataForPath(path);

      switch (urlObject) {
        case UrlObject.MESSAGE:
        case UrlObject.REPLY: {
          const { data } = await refetchMessage({ id: entityId, useTextBody: true });
          return data?.message;
        }
        case UrlObject.USER: {
          const { data } = await refetchUser({ id: entityId, ...userHoverCardDefaultProps });
          return data?.user;
        }
        case UrlObject.BOARD:
        case UrlObject.CATEGORY:
        case UrlObject.GROUPHUB: {
          const { data } = await refetchNode({ id: entityId, ...nodeHoverCardDefaultProps });
          return data?.coreNode;
        }
        default: {
          return null;
        }
      }
    }
  };
}
