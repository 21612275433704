import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import { ListVariant } from '@aurora/shared-client/components/common/List/enums';
import { PagerVariant } from '@aurora/shared-client/components/common/Pager/enums';
import type { PagerVariantTypeAndProps } from '@aurora/shared-client/components/common/Pager/types';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import Icons from '@aurora/shared-client/icons';
import { LoadingSize, LoadingSpacing } from '@aurora/shared-client/types/enums';
import type {
  MessageTagConstraints,
  Tag,
  TagConnection
} from '@aurora/shared-generated/types/graphql-schema-types';
import type { EndUserPages, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { ItemType, TagViewVariant } from '../../../types/enums';
import type {
  MessageBasicFieldsFragment,
  TagListQuery,
  TagListQueryVariables,
  TagViewFragment
} from '../../../types/graphql-types';
import type { ItemListCommonProps } from '../../common/List/ItemList';
import ItemList from '../../common/List/ItemList';
import type { ItemViewTypeAndProps } from '../../entities/types';
import localStyles from './TagList.module.pcss';
import tagsQuery from './TagList.query.graphql';

export type TagListProps = ItemListCommonProps<
  Tag,
  ItemViewTypeAndProps<ItemType.TAG, TagViewVariant>
>;

interface Props extends TagListProps {
  /**
   * The message the tags belong to.
   */
  message: MessageBasicFieldsFragment;
  /**
   * The class name(s) applied to the component element.
   */
  className?: string;
}

/**
 * A list of tags for the given message.
 *
 * @constructor
 * @author Jonathan Bridges
 */

const TagList: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  message,
  pageSize = 5,
  listVariant = {
    type: ListVariant.UNWRAPPED,
    props: {
      listItemClassName: (): string => 'd-block'
    }
  },
  pagerVariant = {
    type: PagerVariant.LOAD_MORE,
    props: {
      loadingSpacing: LoadingSpacing.NONE,
      loadingSize: LoadingSize.SM,
      useCaret: false,
      clearLoadingAfterUpdate: true
    }
  },
  viewVariant = {
    type: TagViewVariant.CHIP
  }
}) => {
  const constraints: MessageTagConstraints = {
    revisionNum: {
      eq: message?.revisionNum
    }
  };
  const cx = useClassNameMapper(localStyles);

  const queryResult = useQueryWithTracing<TagListQuery, TagListQueryVariables>(module, tagsQuery, {
    variables: {
      constraints,
      messageId: message.id,
      first: pageSize
    },
    skip: IdConverter.isOptimistic(message?.id)
  });

  const TagPager: React.FC<React.PropsWithChildren<unknown>> = () => {
    return (
      <Icon icon={Icons.EllipsisIcon} size={IconSize.PX_14} className={cx('lia-pager-icon')} />
    );
  };

  const decoratedPagerVariant: PagerVariantTypeAndProps<EndUserPages, EndUserQueryParams> = {
    ...pagerVariant
  };
  decoratedPagerVariant.props.className = cx('lia-pager');
  decoratedPagerVariant.props.children = TagPager(null);

  return (
    <ItemList<
      TagViewFragment,
      ItemType.TAG,
      ItemViewTypeAndProps<ItemType.TAG, TagViewVariant>,
      TagListQuery,
      TagListQueryVariables
    >
      type={ItemType.TAG}
      variant={viewVariant}
      queryResult={queryResult}
      itemPath="message.tags"
      onUpdate={(tagConnection: TagConnection): TagListQuery => {
        if (
          message.__typename === 'BlogTopicMessage' ||
          message.__typename === 'ForumTopicMessage' ||
          message.__typename === 'TkbTopicMessage' ||
          message.__typename === 'IdeaTopicMessage' ||
          message.__typename === 'OccasionTopicMessage'
        ) {
          return {
            message: {
              ...message,
              tags: tagConnection
            }
          };
        }
        return {
          message: {
            ...message
          }
        };
      }}
      pagerVariant={decoratedPagerVariant}
      listVariant={listVariant}
      pageSize={pageSize}
      loadingSize={null}
      className={cx('lia-list', className)}
      data-testid="tagList"
    />
  );
};

export default TagList;
